@import '../../../node_modules/rupture-sass/rupture';

@font-face {
    font-family: "Lora Regular";
    src: url('../fonts/lora/lora-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Lora Italic";
    src: url('../fonts/lora/lora-italic-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Telefon Bold";
    src: url('../fonts/telefon-bold/telefon_web_bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

$PAPA-BEAR-BREAKPOINT: 1480px;
$MAMA-BEAR-BREAKPOINT: 1300px;
$TEEN-BEAR-BREAKPOINT: 1080px;
$TWEEN-BEAR-BREAKPOINT: 840px;
$TODDLER-BEAR-BREAKPOINT: 680px;
$INFANT-BEAR-BREAKPOINT: 400px;
$NEWBORN-BEAR-BREAKPOINT: 320px;
$MOBILE-BREAKPOINT: 700px;
$MIN-APP-WIDTH: 300px;
$MAX-CONTENT-WIDTH: 1180px;

$MOBILE-FONT-REDUCE-BREAKPOINT: 1280px; 
$MOBILE-HEADER-BREAKPOINT: 1190px;
$MOBILE-SUBNAV-COLLAPSE-BREAKPOINT: $INFANT-BEAR-BREAKPOINT;
$FOOTER-COLUMN-BREAKPOINT: $TEEN-BEAR-BREAKPOINT;

$gray_darkest: #3d4240;
$gray_dark: #3D4152;
$gray_medium: #768a8c;
$gray_light: #abb0af;
$cream: #ffdead;
$cream_light: #fdf4e2;
$cream_white: #fffef3;
$red:#f37163;
$blue: #76a4d7;
$lightBlue: #eaf1f9;

$body-text-color: #5b687f; 
$body-background-color: #FFF; 

$lora-regular: "Lora Regular", Lora, Georgia, serif;
$lora-italic: "Lora Italic", Lora, Georgia, serif;
$telefon-bold: "Telefon Bold", Telefon, Helvetica Bold, Helvetica, sans-serif;

body {
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
}

.overlay-wrapper {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba($blue,0.5);
  padding: 5%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
}

.overlay {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center; 
  background-color: #FFF;
  width: 90%;
  height: auto;
  max-width: 900px;
  margin: 0 auto;

  padding: 2rem 3rem 4rem;

  h3 {
    color: $blue;
    font-size: 2rem;
    margin-bottom: 0.5em;
  }

  p {
    font-size: 1.25em;
    margin-bottom: 0.5em;
  }

  .a-button {
    font-size: 1.25em;
    margin-top: 2.5rem;
  }

  @include below(680px) {
    justify-content: flex-start;
    padding: 2rem 3rem;

    .a-button {
      margin-top: 1rem;
    }

    p {
      font-size: 1em;
    }
  }
}

.root {
    margin: 0 auto;
    overflow: hidden;
    color: $body-text-color; 
    min-height: 100vh;
    min-width: 100%;
    background-color: #F5F7FA; 
}

.a-inline-svg {
  display: inline-block;
  width: 1.5em;
  height: 1em; 
  vertical-align: sub;
  margin: 0 0.25em; 
}

.app {
  width: 100%; 
}

ul, li {
    list-style-type: none;
}

h1,h2,h3,h4,h5 {
  font-weight: normal; 
}

h4 {
    font-size: 20px;
    line-height: 1;
}

h5 {
    font-size: 1.3125em;
    line-height: 1;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box; 
}

p {
    font-family: $lora-regular;
    
    > i {
      font-weight: normal; 
      font-style: normal;
      font-family: $lora-italic;
    }

    .a-highlight {
      font-family: $telefon-bold;
      text-transform: uppercase;
      font-style: normal; 
      background-color: $lightBlue; 
    }
}

h1, h2, h3, h4, h5 {
    font-family: $telefon-bold;
}

a {
    cursor: pointer;
    transition: color 0.25s ease-in-out; 
    color: $blue;
    &:hover {
        color: $red; 
    }
}

.link-button {
  @extend a;
  width: auto;
  background-color: transparent;
  outline: 0;
  border: 0;
  text-align: inherit;
}

.section__inner {
    max-width: $MAX-CONTENT-WIDTH;
    margin: 0 auto;

    @include below(1200px) {
      padding: 0 3rem; 
    }

    @include below(680px) {
      padding: 0 2rem; 
    }
}

a.action-link {
    font-family: $telefon-bold;
    font-size: inherit;
    text-transform: uppercase;
}

.a-hero {
    position: relative; 
    background-color: #76a4d7;
    padding-top: 5em;
    padding-bottom: 1px;
    text-align: center;
    color: #FFF; 

    @include below(550px) {
      padding-top: 1.25rem; 
    }

    a {
        color: $cream;
        &:hover {
            color: #FFF; 
        }
    }

    &__title {
        font-size: 2.953125em;
        line-height: 1.1;
        font-family: $telefon-bold;
        color: #fff;
        padding-left: 1em;
        padding-right: 1em;
        padding-bottom: 1rem;
        margin: 0.25em auto 1em; 
        position: relative;
        z-index: 2;
        max-width: 600px;

        @include below(550px) {
          margin-top: 1rem;
          font-size: 1.5em;
          line-height: 1;
          padding-bottom: 0; 
        }
    }
}

.badge-logo--bg {
    fill: #7ba6de;
    stroke: #ffdead;
    stroke-width: 100px;
    transform-origin: center; 
    border-radius: 50%;
    overflow: hidden;
}

.home-logo__beta-badge-word,
.home-logo__bg {
  fill: $blue;
}

.home-logo__beta-badge,
.home-logo--border-bg {
  fill: $cream; 
}

.badge-logo--standard-letters,
.badge-logo--arrow {
    fill: #fff;
}

.badge-logo--highlight-letters,
.badge-logo--cursive-g,
.badge-logo--pins {
    fill: #ffdead;
}

.badge-logo--checkmark {
  stroke: #ffdead;
  stroke-width: 180;
  fill: none; 
  stroke-linecap: round;
  stroke-linejoin: round;
}

.o-header {
  .gp-at-home-logo {
    width: 7vw;
    height: 7vw;
    min-width: 7em;
    min-height: 8em;
    margin: 0 auto;

    @include below(550px) {
      width: 5rem;
      height: 5rem;
      min-height: 5rem;
      min-width: 5rem;
    }

    @include below(380px) {
      width: 3rem;
      height: 3rem;
      min-height: 3rem;
      min-width: 3rem;
    }
}
  .badge-logo--bg {
    @include below(550px) {
      stroke: transparent;
      stroke-width: 0; 
      fill: transparent; 
    }
  }
}

.gp-at-home-logo--disabled {
  .home-logo__bg {
    fill: $gray_light;
  }
  
  .home-logo--border-bg {
    fill: #999; 
  }
  
  .badge-logo--standard-letters,
  .badge-logo--arrow {
      fill: #fff;
  }
  
  .badge-logo--highlight-letters,
  .badge-logo--cursive-g,
  .badge-logo--pins {
      fill: #fff;
  }
}

.o-header {
  .badge-logo--standard-letters,
  .badge-logo--highlight-letters {      
    @include below(550px) {
      fill: transparent;
    }
  }
}

.o-header {
  &__app-title {
    display: none; 
  }
}

.m-progress-bar-tabs {
  display: block;
  width: 100%;
  margin-bottom: 1rem; 

  &__list {
    display: flex;
    width: 100%;
    justify-content: stretch;
    align-items: center;
    background-color: #FFF; 
    border-radius: 3rem;
    height: 3rem;
    overflow: hidden; 
    border: 1px solid $lightBlue; 
  }
  
  &__list-item {
    position: relative; 
    z-index: 2; 
    flex: 1; 
    overflow: hidden;

    &-link {
      display: block; 
      width: calc(100% - 30px);
      height: 100%;
      text-align: center;
      padding: 1em;
    }

    * {
      transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out; 
    }
    
    &:not(:last-of-type) {
      &:before {
        transition: border-left-color 0.25s ease-in-out;
        z-index: -1; 
        right: -90px;
        top: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: transparent; 
        border-left-color: $lightBlue;
        border-width: 92px;
        margin-top: -92px;
      }
      &:after {
        z-index: -1; 
        right: -86px;
        top: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: transparent; 
        border-left-color: #FFF;
        border-width: 90px;
        margin-top: -90px;
      }
    }
    &.selected {

      .m-progress-bar-tabs__list-item-link {
        color: #FFF; 
        background-color: $blue;  
      }

      &:after {
        border-left-color: $blue; 
      }
    }

    &:hover {
      .m-progress-bar-tabs__list-item-link {
        color: $body-text-color;
      }
    }
  }
}


.headroom--pinned,
.headroom--unpinned {
  .o-header {
    @include below(550px) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      background-color: $blue; 
      z-index: 4;
      padding: 0;
    }
  }

  .o-header-nav__items {
    @include below(550px) {
      padding: 0 0.8rem;
    }
  }
}

.headroom--pinned {
  @include below(860px) {
    .o-header__app-title {
     // display: block;
    }

    .o-header__logo {
      display: none; 
    }
  }
}

.headroom--unpinned {
  .o-header__app-title {
    display: none;
  }

  .o-header__logo {
    display: block;
  }
}

.rag-line--dots {
    fill: none;
    stroke-linecap: round;
    stroke-miterlimit: 10;
    stroke-width: 3.299999952316284px;
    stroke-dasharray: 0,16.5,0,16.5,0,16.5;
}

/*! raglines.import.styl */

.rag-line {
    display: block;
    position: absolute !important;
    left: -15%;
    z-index: 100;
    &__extrude {
      z-index: 1;
      ~ * {
        position: relative;
        z-index: 2;
      }
      .rag-line {
        &--paper {
          fill: $blue;
        }
        &--dots {
          stroke: $blue;
        }
      }
    }
    &__top-extrude {
      top: -4.5em !important;
    }
    &__bottom-extrude {
      //top: -5em !important;
    }
    &__inset {
      .rag-line {
        &--paper {
          fill: $body-background-color;
        }
        &--dots {
          stroke: $body-background-color;
        }
      }
    }
    &__outset {
      .rag-line {
        &--paper {
          fill: $body-background-color;
        }
        &--dots {
          stroke: $blue;
        }
      }
    }
  }
  
  .rag-line__bottom {
    width: 100%;

    @include above($PAPA-BEAR-BREAKPOINT) {
      width: 125%;
    }

    @include above($TEEN-BEAR-BREAKPOINT) {
      bottom: -5em;
    }

    @include below($TEEN-BEAR-BREAKPOINT) {
      width: 200%;
    }

    @include below($TODDLER-BEAR-BREAKPOINT) {
      width: 300%;
    }

    @include below($INFANT-BEAR-BREAKPOINT) {
      width: 350%;
    }
  }
  
  .rag-line {
    transition: width 1s ease-in-out;
    width: 125%;
  }
  
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rag-line {
      height: 7em;
    }
  }
  
  .o-header-nav {
      &__items {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 5em;

        @include below(550px) {
          transition: padding 0.25s ease-in-out; 
        }

          > li {
            flex: 1; 
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            &:first-of-type {
                text-align: left;

                align-items: flex-start;
            }

            &:last-of-type {
                text-align: right;

                align-items: flex-end; 
            }

              h5 {
                  font-family: $telefon-bold;
                  font-size: 20px;
              }
          }
      }

      &__link-button {
        @include below(860px) {
          padding: 1.2em 1em;
          border: 1px solid currentColor;
          border-radius: 3px; 
          text-align: center;
          display: inline-block;

          > h5 {
            font-size: 1rem !important; 
          }
        }

        @include below(380px) {
          padding: 1em 0.8em;

          > h5 {
            font-size: 3.5vw !important;
          }
        }
      }

      &__help-link {
        @include below(860px) {
          display: none;
        }
      }

      &__mobile-menu-trigger {
        display: none; 
        @include below(860px) {
          display: inline-block;
        }
      }
  }

  .main-content {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      max-width: 1180px;
      margin: 7rem auto;

      @include below(680px) {
        margin: 3.5rem auto 2rem;
      }
  }

  .o-nav-sidebar {
    transition: transform 0.25s ease-in-out;
    display: block;

    &__column-wrapper {
      @include above(860px) {
        position: relative;
        width: 285px;
        height: 100vh; 
        margin-right: 2rem;
      }

      @include below(860px) {
        position: relative;
        z-index: 10; 
      }
    }

    @include below(860px) {
      transform: translateX(-285px);
      width: 285px; 
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      z-index: 5; 
      margin-right: 0; 

      &--mobile-exposed {
        transform: translateX(0);
      }
    }

    @include below(550px) {
      width: 90%;
      border: none; 
      transform: translateX(-100%);
      text-align: center;

      &--mobile-exposed {
        transform: translateX(0);
      }
    }

    &__scrim {
      display: none;

      @include below(860px) {
        &--mobile-exposed {
          display: block;
          position: fixed;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          z-index: 2;
          background-color: rgba(0,0,0,0.25);
        }
      }
    }

    &__title {
      display: none;

      @include below(860px) {
        display: block;
        text-align: center; 
        font-size: 1.5em; 
        margin-top: 1rem;
      }
    }

    &__mobile-action-buttons {
      display: none;

      @include below(860px) {
        display: block;
        width: 100%; 
        position: fixed;
        bottom: 0;
        left: 0;
        margin-bottom: 2rem;
      }
    }

    a {
        text-decoration: none; 
        color: $blue;
        &:hover,
        &:active,
        &.active {
            color: $red; 
        }
    }

    .fa {
        width: 1em;
        text-align: center;
    }
  }

  .o-nav-sidebar__sticky-wrapper {
    @include below(860px) {
      transform: none !important; 
    }
    &--sticky {
      @include above(860px) {
        .o-nav-sidebar {
          transform: translateY(2rem);
        }

        .o-nav-sidebar__title {
          display: block; 
          text-align: center; 
          font-size: 1.5em; 
          margin-top: 1rem;
        }
      }
    }
  }

  .o-forms-container,
  .o-nav-sidebar {
    padding: 0.5rem 0 1.5rem;
    background-color: #FFF; 
    border: 1px solid $lightBlue;
  }

  .o-nav-sidebar {
    li > a {
        display: block;
        width: 100%;
        height: 100%; 
        padding: 1rem 1.5rem 0;
    }
  }

  .a-stepper-label {
    font-size: 0.8em;
    text-transform: uppercase;
  }
  
  .MuiStepLabel-active .a-stepper-label {
    color: $blue;
  }

  .o-form__title {
      color: $body-text-color; 
      font-size: 1.2em;
  }

  .o-form__subtitle {
    font-family: $lora-italic;
  }

  .app-content {
    flex: 1;
    width: 100%; 
  }

.o-form {
  display: flex;
  flex-direction: row;
  width: 100%; 
  padding: 0 1.5rem;
  justify-content: space-between;
  flex-wrap: wrap;

  &__item-label {
    margin-top: 12px;
  }

  > li {
    width: 100%; 
  }

  > li.o-form__list-item--half {
    width: calc(50% - 0.75rem);
  }

  > li.o-form__list-item--split {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    .o-form__item-label {
      width: 100%;
    }
  }

  > li.o-form__list-item--padded {
    margin-top: 0;
    margin-bottom: 12px; 
    
    .o-form__item-label {
      margin-bottom: 16px; 
    }
  }
}

.a-input-field__half-size {
  width: calc(50% - 0.75rem);
}

    
.o-forms-container {
    display: block;
    margin: -1rem auto 5rem; 
    position: relative;
    z-index: 200; 
    max-width: 800px;

    @include below(800px) {
      z-index: 2; 
      margin-top: 3rem; 
      background-color: transparent; 
      border-color: transparent; 
    }

    &__title {
        display: inline-block;
        position: relative;
        color: $blue; 
        margin-bottom: 0.5rem; 
    }

    &__subtitle {
        font-family: $lora-italic; 

        @include above(800px) {
          padding: 0 8.5rem; 
        }
    }

    &__title-group {
        position: relative;
        margin: 1rem 1.5rem 1.5rem;
        text-align: center; 

        &:after {
            content: '';
            position: absolute;
            left: calc(50% - 1.25rem);
            bottom: calc(-1rem + 1px);
            display: block;
            width: 2.5rem;
            height: 2px;
            background-color: $blue;
        }
    }
}

.a-button {
    outline: 0;
    transition: color .25s ease-in-out,background-color .25s ease-in-out,box-shadow .25s ease-in-out;
    cursor: pointer;
    display: inline-block;
    padding: 0.75em 1em 0.5em;
    font-size: 0.85rem;
    font-family: $telefon-bold;
    color: #fff;
    border-color: transparent;
    border-radius: 3px;
    background-color: #76a4d7;
    box-shadow: 0 3px 0 0 #5c93cf;
    white-space: nowrap;

    @include below(860px) {
      font-size: 0.75rem;
    }

    &:hover {
        background-color: $red;
        box-shadow: 0 3px 0 0 $red; 

        * {
          text-decoration: none; 
          box-shadow: none; 
        }
    }

    &--full-on-mobile {
      @include below(860px) {
        width: 100%;
        text-align: center;
      }
    }

    &--gph-dark {
      background-color: #5b687f;
      box-shadow: 0 3px 0 0 #465165;

      &:hover {
        background-color: #76a4d7;
        box-shadow: 0 3px 0 0 #76a4d7;
      }
    }
}

.completed-icon {
    color: $blue;
}

.removed-icon {
    color: $red; 
}

h5.highlight-title {
  color: $body-text-color;
  font-size: 0.85rem; 
  text-align: center; 
}

/*! footer.import.styl */

.footer {
  padding: 3rem 0;
  color: #FFF;
  background-color: lighten($body-text-color, 10%);

  @include below($FOOTER-COLUMN-BREAKPOINT) {
    padding: 2rem 0;
    line-height: 2.5;
  }

  &__inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between; 
    max-width: 1180px;
    margin: 0 auto; 

    @include below($FOOTER-COLUMN-BREAKPOINT) {
      flex-direction: column; 
      text-align: center;
      justify-content: center;
    }
  }

  p {
    font-size: 0.8rem;
    line-height: 1.5;
    text-align: inherit;
  }

  h5 {
    text-align: inherit;
    line-height: 1.9; //keeps things nicely in line with social icons
  }

  a {
    color: #FFF;
    &:hover {
      cursor: pointer;
      color: $cream;
    }
  }

  .fa-mobile-phone {
    font-size: 1.5rem;
    vertical-align: sub;
  }

  &--item {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:first-child {
        text-align: left;
    }
    &:nth-child(2) {
      text-align: center;
    }
    &:last-child {
        text-align: right;
    }

    @include below($FOOTER-COLUMN-BREAKPOINT) {
      align-items: center; 
      text-align: center !important;
    }
  }

  &--phone-item {
    font-size: 1.25em;
    a {
        text-decoration: none; 
    }
  }
}

.social-media>li {
    display: inline-block;
    font-size: 2rem;
    margin-left: 0.1rem;
    margin-right: 0.1rem;
}

.a-list__gridded {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 680px;

    @include below(860px) {
      grid-template-columns: 100%;
      max-width: 100%; 
      width: 100%;
    }
}

.a-confirmation-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1rem;
  height: 100%;
  width: 100%;

  color: $body-text-color;

  h4 {
    margin-top: 1rem;
    margin-bottom: 0.25rem;
  }
}

.o-map-embed {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%; 
  background-color: $blue;

  &__wrapper {
    width: 100%; 
    height: 650px; 
    padding: 0 1.5rem;
    margin-top: 2rem; 

    @include below(800px) {
      height: 60vh;
      min-height: 650px;
    }
  }

  &__loading-message {
    text-align: center; 
    color: #FFF; 
  }

  &__marker {
    position: absolute;
    width: 30px;
    height: 30px;
    left: -15px;
    top: -15px;  

    svg {
      position: absolute;
      width: 100%; 
      left: 0;
      top: 0; 
    }
  }

  &__marker-wrapper {
    cursor: pointer; 
    position: absolute;
    z-index: 1; 

    &--selected {
      z-index: 200;
    }
  }


  &__popup-bubble--hidden {
    display: none; 
  }

  &__popup-bubble--exposed {
    position: absolute;
    display: block;
    z-index: 200; 
    width: 17.5rem;
    background-color: #FFF; 
    bottom: calc(20px + 120%);
    margin-bottom: 1rem;  
    left: calc(-17.5rem / 2);
    padding: 1em; 
    box-shadow: 10px 10px 7px -9px rgba(0,0,0,0.41); 

    &:after {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: '';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: transparent; 
      border-top-color: #FFF; 
      border-width: 10px;
      margin-left: -10px;
      box-shadow: 10px 10px 7px -9px rgba(0,0,0,.81); 
    }
  } 

  &__popup-bubble-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1rem;
    margin-bottom: 0.2rem; 

    > li {
      flex: 1; 
    }

    &--2-item {
      > li {
        flex: 0 1 auto; 
        width: calc(50% - 0.35rem);
      }
    }
  }

  &__button {
    width: 100%;

    &--disabled {
      cursor: not-allowed;
      background: $gray_light;
      box-shadow: 0 3px 0 0 #999;

      &:hover,
      &:active {
        background: $gray_light;
        box-shadow: 0 3px 0 0 #999;
      }
    }
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

.m-details-page {
  animation: slideIn 0.35s ease-in-out;
  transform:translateY(0);
  position: absolute;
  z-index: 201;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; 
  background-color: #FFF;
  overflow: hidden; 
  
  &--exposed {
    background-color: #FFF; 
    border: 1px solid $lightBlue; 
  }

  &__inner {
    display: block; 
    overflow: auto; 
    padding: 3rem 3rem 0; 
    height: 100%;
    width: 100%; 
  }

  &__info-chart {
    margin-bottom: 2rem;
  }

  &__icon-list {
    flex-wrap: wrap; 

    > li {
      padding: 0.5rem; 
      flex: 1;
      width: auto; 

      @include below(800px) {
        width: 50%;
        flex: auto !important;  
      }
    }

    p {
      font-size: 0.85rem;
    }
  }

  &__map-circle {
    position: relative;
    display: block;
    margin: 0 auto 1rem;
    height: 8rem;
    width: 8rem;
    overflow: hidden; 
    border: 2px solid $blue; 
    border-radius: 50%; 
  }

  &__close-button {
    position: fixed;
    z-index: 100; 
    right: 3rem;
    top: 3rem; 
  }

  &__action-button-wrapper {
    position: fixed;
    width: 100%;

    z-index: 202; 
    height: 4rem;
    width: 100%;
    bottom: 0; 
    padding-bottom: 2rem; 
    left: 0;
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 67%);

    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__text-item {
    margin-bottom: 1rem; 
    &:last-of-type {
      &:after {
        content: "";
        height: 7rem;
        display: block;
      }
    }
    h5 {
      position: relative;
      margin-bottom: 0.8rem; 
      &:after {
        content: '';
        height: 2px;
        width: 2rem;
        background-color: $blue;
        position: absolute;
        left: 0;
        bottom: -0.5rem; 
      }
    }
  }
}

.m-info-chart {

  &__title-group {
    display: block;
    position: relative;
    text-align: center; 
    margin-bottom: 2rem;

    &:after {
      content: '';
      height: 2px;
      width: 2rem;
      background-color: $blue;
      position: absolute;
      left: calc(50% - 1rem);
      bottom: -1rem; 
    }

    h5 {
      color: $blue;
      line-height: 1.2;
    }
  }

  &__subtitle {
    max-width: 425px;
    margin: 0 auto;
  }

  &__icon-list {
    width: 100%; 
    display: flex;
    justify-content: center; 

    > li {
      width: 33.3333%; 
      text-align: center; 
    
      h5 {
        font-size: 0.65rem; 
        text-transform: uppercase;
      }

      > p {
        margin-top: 0.25rem; 
      }
    }
  }

  &__icon-wrapper {
    display: block;
    width: 100%;
    margin-bottom: 0.5rem;

    svg {
      fill: $blue; 
      height: 2rem;
    }
  }
}

.m-weekday-chart {
  display: flex;
  flex-direction: row;
  margin-top: 0.25rem;
  width: 100%;
  border: 1px solid $lightBlue;
  border-radius: 1rem;
  overflow: hidden;

  > li {
    flex: 1; 
    height: 1rem;
    flex-direction: column;
    align-items: center; 
    justify-content: center;

    > p {
      font-size: 0.5rem !important;
      line-height: 0.8rem;
      text-transform: uppercase; 
    }

    &:not(:last-of-type) {
      border-right: 1px solid $lightBlue; 
    }
  }

  &__item--highlighted {
    background-color: $blue;
    color: #FFF; 
  }
}

.o-share-buttons {
  margin-top: 2rem;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 1.5rem;

  @include below(800px) {
    flex-direction: column;
  }

  &:before {
      content: '';
      position: absolute;
      left: calc(50% - 1.25rem);
      top: -0.75rem;
      display: block;
      width: 2.5rem;
      height: 2px;
      background-color: $blue;
  }
}


.o-share-buttons__list {
    display: flex;
    max-width: 300px;
    margin-left: 0.5rem;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  
    > li {
      transition: background-color 0.25s ease-in-out;
      border: 1px solid $lightBlue; 
      height: 3.5em;
      width: 3.5em;
      border-radius: 50%;
      margin: 0 0.25em;
      color: $blue; 
      overflow: hidden;
      * {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center; 
        height: 100%;
        width: 100%;
        outline: 0;
      }
      &:hover {
        background-color: $blue;
        * {
          color: #FFF;
        }
      }
    }

    @include below(800px) {
      margin-left: 0; 
    }
}

.o-share-buttons__title {
  display: block;
  width: 350px; 
  text-align: left;
  margin-right: 0.5rem;
  
  @include below(800px) {
    text-align: center;
    margin-bottom: 1rem;
    margin-right: 0; 
  }
}

.a-circle-number {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  margin: 0 auto 0.5rem;
  background-color: $blue;
  
  h5 {
    line-height: 1;
    font-size: 1em;
    text-align: center;
    font-family: $telefon-bold;
    color: #FFF;
  }
}

.a-steps-instructions {
  &__item {
    margin-top: 1rem;
  }
  &__button-link {
    padding-left: 0.5rem;

    @include below(800px) {
      padding-left: 0;
      padding-top: 1rem;
      padding-bottom: 1rem;
      width: 200px;
      .a-button {
        width: 100%; 
      }
    }

    @include below(500px) {
      width: 100%; 
    }
  }
  &__item--w-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: left;

    @include below(800px) {
      text-align: center; 
      flex-direction: column;
    }
  }
}

.a-bottom-tag {
  text-align: center;
  margin-top: 3rem;
}